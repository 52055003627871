import { useEffect, useState, useRef } from 'react'

import { Spinner } from '@extensiv/shared-reactcomponents'
import * as globalUtility from '@extensiv-app/utility'
import { Box } from '@mui/material'
import axios from 'axios'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import { useIdleTimer } from 'react-idle-timer'
import { SingleSpaCustomEventDetail } from 'single-spa'

import CssClasses from '@core/assets/styles/css-classes'
import { LOGIN_PREVIEW } from '@core/constants/coreRoutes'
import { setIsFormEdited } from '@core/redux/appSlice'
import { getSession, logout, getUserInfo, setCurrentOrgLogo } from '@core/redux/authSlice'
import { clearStateSnackbar } from '@core/redux/snackbarSlice'
import { useAppDispatch, useAppSelector } from '@core/redux/store'
import Routes from '@core/routes'
import { HubProduct } from '@core/types/Application'
import { useSnackBar } from '@core/util/commonFunctions'
import { sfChat, SalesforceChatDetail } from '@core/util/SalesforceChat'
import { websocketMessageRouter } from '@core/websockets/WebsocketMessageRouter'


export default () => {
  const { classes: commonClasses } = CssClasses()
  const dispatch = useAppDispatch()
  const [ isLoading, setIsLoading ] = useState(true)
  const { cognitoSession, currentUser, currentOrg, allowAuthPropagation } = useAppSelector((state: any) => state.auth)
  const { isFormEdited, appsNeedingReloadForUpdate, selectedApp } = useAppSelector((state: any) => state.application)
  const { msg, type, option } = useAppSelector((state: any) => state.snackbar)
  const { showSuccessMessageAlert, showInformationMessageAlert, showWarningMessageAlert, showErrorMessageAlert } = useSnackBar()

  const isAuthenticated = cognitoSession && cognitoSession.idToken ? true : false
  const appsNeedingReload = useRef(appsNeedingReloadForUpdate)
  appsNeedingReload.current = appsNeedingReloadForUpdate

  useEffect(() => {
    if (window.location.href.includes('#im')) {
      window.location.href = '/integration-manager'
    } else if (window.location.href.includes('#om')) {
      window.location.href = '/order-manager'
    }

    const reloadWhenTokenUpdates = ({ key }: any) => {
      if (key && key.endsWith('idToken')) {
        window.location.reload()
      }
    }

    // Upon route change, check if any applications have been updated in the background.
    // If we're navigating to an application that has been updated, perform a hard
    // refresh in order to fetch new application code, since we can't do this
    // silently through single-spa due to systemjs import-map constraints.
    const beforeRoutingEvent = (evt: CustomEvent<SingleSpaCustomEventDetail>) => {
      Object.entries(evt.detail.newAppStatuses).forEach(([ app, status ]) => {
        if (status === 'MOUNTED' && appsNeedingReload.current.includes(app)) {
          window.location.reload()
        }
      })
    }

    // @ts-ignore
    window.addEventListener('single-spa:before-routing-event', beforeRoutingEvent)
    window.addEventListener('storage', reloadWhenTokenUpdates)

    return () => {
      // @ts-ignore
      window.removeEventListener('single-spa:before-routing-event', beforeRoutingEvent)
      window.removeEventListener('storage', reloadWhenTokenUpdates)
    }
  }, [])

  useEffect(() => {
    if (currentUser && currentOrg && selectedApp) {
      // Dont initialize on any of the skipped apps, and hide button if already initialized
      const sfChatIncompatibleApps = [
        HubProduct.SMARTSCAN,
        HubProduct.ADMIN,
        HubProduct.WAREHOUSE_MANAGER_MOBILE,
        HubProduct.MANAGEMENT_CONSOLE,
        HubProduct.SIGNUP
      ]

      if (sfChatIncompatibleApps.includes(selectedApp.key)) {
        sfChat.setChatButtonVisibility(false)

        return
      }

      sfChat.setFormDetailValue(SalesforceChatDetail.APP, selectedApp.key)
      sfChat.setFormDetailValue(SalesforceChatDetail.ORGKEY, currentOrg.key)
      sfChat.setFormDetailValue(SalesforceChatDetail.FIRSTNAME, currentUser.firstName)
      sfChat.setFormDetailValue(SalesforceChatDetail.LASTNAME, currentUser.lastName)
      sfChat.setFormDetailValue(SalesforceChatDetail.EMAIL, currentUser.email)

      if (!sfChat.isInitialized) {
        sfChat.initializeChat()
      } else {
        sfChat.setChatButtonVisibility(true)
      }
    } else if (sfChat.isInitialized) {
      // If it was initialized, but we for some reason dont have an org, user
      // or selectedApp
      sfChat.setChatButtonVisibility(false)
    }

    return () => {
      if (sfChat.isInitialized) {
        sfChat.setChatButtonVisibility(false)
      }
    }
  }, [ currentUser, currentOrg, selectedApp ])

  useEffect(() => {
    // Publish session updates whenever the session changes
    if (allowAuthPropagation && !isEqual(globalUtility?.globalAuthData?.getValue(), cognitoSession)) {
      globalUtility.globalAuthData.next(cognitoSession)
    }
  }, [ cognitoSession, allowAuthPropagation ])

  useEffect(() => {
    if (!isEqual(globalUtility?.globalAuthUser?.getValue(), currentUser)) {
      globalUtility.globalAuthUser.next(currentUser)
    }
  }, [ currentUser ])

  useEffect(() => {
    if (globalUtility?.isAnyFormEdited?.getValue() !== isFormEdited) {
      globalUtility.isAnyFormEdited.next(isFormEdited)
    }
  }, [ isFormEdited ])

  useEffect(() => {
    getUserSession()

    const selectedOrgSubscription = globalUtility.globalSelectedOrg.subscribe(
      (selectedOrg) => {
        if (selectedOrg?.logo) {
          dispatch(setCurrentOrgLogo(selectedOrg.logo))
        }
      }
    )

    const isAnyFormEditedSubscription = globalUtility.isAnyFormEdited.subscribe((globalFormEdited: any) => {
      dispatch(setIsFormEdited(globalFormEdited))
    })

    const isGlobalLogoutSubscription = globalUtility.isGlobalLogout.subscribe((isGlbLogout: any) => {
      if (isGlbLogout) {
        dispatch(logout())
        globalUtility.isGlobalLogout.next(false)
      }
    })

    const fetchUserInfoSubscription = globalUtility.fetchUserInfo.subscribe(async (userId: string | null) => {
      if (userId) {
        await dispatch(getUserInfo(userId))
        globalUtility.fetchUserInfo.next(null)
      }
    })

    const websocketMessageSubscription = globalUtility.websocketMessages.subscribe(websocketMessageRouter.routeMessage)

    return () => {
      selectedOrgSubscription.unsubscribe()
      isAnyFormEditedSubscription.unsubscribe()
      isGlobalLogoutSubscription.unsubscribe()
      fetchUserInfoSubscription?.unsubscribe()
      websocketMessageSubscription?.unsubscribe()
    }
  }, [])

  const getUserSession = async () => {
    await dispatch(getSession())
    interceptAxiosResponse(dispatch)
    setIsLoading(false)
  }

  useEffect(() => {
    if (msg) {
      switch (type) {
        case 'success':
          showSuccessMessageAlert(msg, option)
          break
        case 'info':
          showInformationMessageAlert(msg, option)
          break
        case 'warning':
          showWarningMessageAlert(msg, option)
          break
        case 'error':
          showErrorMessageAlert(msg, option)
          break
        default:
          break
      }
      dispatch(clearStateSnackbar())
    }
  }, [ msg, type ])

  const interceptAxiosResponse = (dispatch: any) => {
    axios.interceptors.response.use((response: any) => response, async (error: any) => {
      const errorResponse = error.response
      // Reject promise if usual error
      if (errorResponse && errorResponse.status === 401) {
        setTimeout(async () => {
          await dispatch(setIsFormEdited(false))
          dispatch(logout())
        }, 100)
      }
      return Promise.reject(error)
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const idleTimer = useIdleTimer({
    onIdle: () => dispatch(logout()),
    onActive: () => { },
    timeout: 1000 * 60 * 479 // 479 minutes (7h 59m)
  })

  return (
    <Box
      style={{ height: (window.location.pathname !== LOGIN_PREVIEW) && isAuthenticated ? '' : '100vh' }}
      className={ clsx(isLoading && [ commonClasses.dFlexCenter, 'progress-bar' ]) }
    >
      {isLoading
        ? <Spinner width='100%' height='100%' marginTop='0px' marginLeft='0px' className={ commonClasses.spinnerOverride } />
        : <Routes />
      }
    </Box>
  )
}
