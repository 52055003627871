import { makeStyles } from 'tss-react/mui'

import CssVariables from '@core/assets/styles/css-variables'

export const useStyles = makeStyles()(() => ({
  PageContainer: {
    height: '100%',
    backgroundColor: '#D9D9D9',
    flexWrap: 'nowrap'
  },
  LoginFormLeftContainer: {
    flex: 1
  },
  LoginSectionContainer: {
    position: 'relative',
    height: '100%',
    padding: '24px',
    backgroundColor: CssVariables.white
  },
  ForgotPasswordLink: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    textDecorationColor: 'rgba(0, 0, 0, 0.87)'
  },
  Logo: {
    position: 'absolute',
  },
  CenteredFormContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  LoginForm: {
    maxWidth: '360px'
  },
  LoginContent: {
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100% ',
    flex: 1
  },
  LoginContentHTML: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-end'
  },
  LoginContentHTMLContent: {
    background: 'rgba(0, 0, 0, 0.50)',
    padding: '32px',
    color: '#fff'
  },
  ImageOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  AuthFailedExceptionAlert: {
    width: '100%',
    marginBottom: '16px'
  },
  passwordVisibilityIcon: {
    width: '20px',
  },
  formHelperTextProps: {
    position: 'inherit',
    margin: '4px 14px 0px 14px',
    bottom: 'inherit',
    textAlign: 'inherit',
  },
}))
