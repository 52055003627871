import { BaseSyntheticEvent, ChangeEvent, useState, useEffect } from 'react'

import { ExtensivButton, CoreTypography } from '@extensiv/shared-reactcomponents'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Grid, TextField } from '@mui/material'
import { Link, useLocation, useHistory } from 'react-router-dom'

import ExtensivLogo from '@core/assets/img/extensiv-logo-200.svg'
import { AUTH_ROUTES } from '@core/constants/coreRoutes'
import PasswordComponent from '@core/containers/Auth/PasswordComponent'
import { completeNewPasswordChallenge } from '@core/redux/authSlice'
import { confirmPassword } from '@core/redux/forgotPasswordSlice'
import { useAppDispatch, useAppSelector } from '@core/redux/store'

import { useStyles } from './styles'


interface IProps {
  type?: string
}

interface IFormInputs {
  newPassword: string
  email: string
  verificationCode: string
}

interface ResetFlowState {
  email?: string
  verificationCode?: string
  type?: string
}

export default ({ type }: IProps) => {
  const cmpSelector = 'CreatePassword'
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const location = useLocation<ResetFlowState>()
  const history = useHistory()
  const {
    newPasswordSessionUserAttributes
  } = useAppSelector((state: any) => state.login)

  const [ isLoading, setIsLoading ] = useState(false)
  const [ isResetDone, setIsResetDone ] = useState(false)
  const [ formValues, setFormValues ] = useState<IFormInputs>({
    newPassword: '',
    email: '',
    verificationCode: ''
  })

  useEffect(() => {
    if (location?.state?.type !== 'newPasswordRequired') {
      if (!location?.state?.email) {
        history.push(AUTH_ROUTES.LOGIN)
      } else {
        setFormValues({ ...formValues, email: location.state.email })
      }
    }
  }, [])

  const handleChange = (inputKey: keyof IFormInputs) => (event: ChangeEvent<HTMLInputElement>) => {
    const inputVal = event.target.value
    const formValuesObj = { ...formValues, [inputKey]: inputVal }

    setFormValues({ ...formValuesObj })
  }

  const createPassword = async (event: BaseSyntheticEvent) => {
    event.preventDefault()
    setIsLoading(true)

    if (location?.state?.type === 'newPasswordRequired') {
      await dispatch(completeNewPasswordChallenge({
        newPassword: formValues.newPassword,
        newPasswordSessionUserAttributes
      }))
    } else {
      const res = await dispatch(confirmPassword(formValues))
      setIsLoading(false)

      if (res?.type?.includes('fulfilled')) {
        setIsResetDone(true)
      }
    }
  }

  return (
    <Grid container className={ classes.PageContainer }>
      <Grid item xs={ 12 }>
        <img src={ ExtensivLogo } className={ classes.ExtensivLogo } alt='Extensiv logo' />
      </Grid>
      <Grid item xs={ 12 }>
        <Grid container className={ classes.MainFormBody }>
          <Grid item className={ classes.ResetFormContainer }>
            <Grid container rowSpacing={ 5 }>
              <Grid item xs={ 12 }>
                <Link to={ AUTH_ROUTES.LOGIN } className={ classes.LoginLink }>
                  <CoreTypography variant='bodyLg'>
                    <FontAwesomeIcon icon={ faArrowLeft } style={{ marginRight: '8px' }} />
                    Back to login
                  </CoreTypography>
                </Link>
              </Grid>
              <Grid item xs={ 12 }>
                <Grid container rowSpacing={ 1 }>
                  <Grid item xs={ 12 }>
                    <CoreTypography variant='headingXl'>
                      { location?.state?.type === 'newPasswordRequired' ? 'Welcome' : 'Reset password' }
                    </CoreTypography>
                  </Grid>
                  <Grid item xs={ 12 }>
                    { isResetDone ? (
                        <Alert
                          severity='success'
                          icon={ <FontAwesomeIcon icon={ faCircleCheck } /> }
                        >
                          Your password has been changed
                        </Alert>
                    ) : location?.state?.type === 'newPasswordRequired' ? (
                      <CoreTypography variant='bodyMd'>
                        Please create your new password:
                      </CoreTypography>
                    ) : (
                      <Grid container>
                        <Grid item xs={ 12 } className={ classes.VerificationCodeContainer }>
                          <Grid container rowSpacing={ 3 }>
                            <Grid item xs={ 12 }>
                              <CoreTypography variant='bodyMd'>
                                Please enter the verification code sent to <br/>
                                <strong>{ formValues.email }</strong>
                              </CoreTypography>
                            </Grid>
                            <Grid item xs={ 6 }>
                              <TextField
                                label='Verification code'
                                placeholder='e.g. 123456'
                                variant='outlined'
                                onChange={ ({ target: { value } }) => setFormValues({ ...formValues, verificationCode: value }) }
                                value={ formValues.verificationCode }
                                required
                                type='email'
                                InputLabelProps={{ shrink: true }}
                                sx={{ background: '#fff' }}
                                data-testid={ `${cmpSelector}EmailTxtFld` }
                                id={ `${cmpSelector}EmailTxtFldInput` }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={ 12 }>
                <Grid container rowSpacing={ 2 }>
                  { !isResetDone && (
                    <Grid item xs={ 12 }>
                      <PasswordComponent
                        onChange={ handleChange('newPassword') }
                        enabled={ location?.state?.type === 'newPasswordRequired' ? true : formValues.verificationCode.length === 6 }
                        cmpSelector={ cmpSelector }
                        required
                      />
                    </Grid>
                  ) }
                  <Grid item xs={ 12 }>
                    { !isResetDone ? (
                      <ExtensivButton
                        data-testid={ `${cmpSelector}CreateNewPasswordButton` }
                        id={ `${cmpSelector}CreateNewPasswordButton` }
                        color='brand'
                        disabled={ formValues.newPassword === '' }
                        onClick={ createPassword }
                        isLoading={ isLoading }
                        fullWidth
                      >
                        CREATE NEW PASSWORD
                      </ExtensivButton>
                    ) : (
                      <ExtensivButton
                        data-testid={ `${cmpSelector}GoToLoginPageButton` }
                        id={ `${cmpSelector}GoToLoginPageButton` }
                        color='secondary'
                        variant='outlined'
                        onClick={ () => history.push(AUTH_ROUTES.LOGIN) }
                        fullWidth
                      >
                        GO TO LOGIN PAGE
                      </ExtensivButton>
                    ) }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
